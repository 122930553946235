import React from 'react'
import Layout from "../components/Layout"
import SingInCard from "../components/Login/LoginSignIn"
import CreateAccountCard from "../components/Login/LoginCreateAccount"

export default function Login() {
    return (
        <Layout>
            <div className="Login">
                <div className="Login-container">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <SingInCard />
                        </div>
                        <div className="col-12 col-lg-6">
                            <CreateAccountCard />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
