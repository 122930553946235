import React from 'react'
import { Link } from 'gatsby'

export default function LoginCreateAccount() {
    return (
        <div className="LoginCreateAccount">
            <h3 className="LoginCreateAccount-title">Crear una nueva cuenta</h3>
            <span className="LoginCreateAccount-text">
                Si aún no tienes una cuenta de usuario en Laie, utiliza esta opción para acceder al formulario de registro.
            </span>
            <Link className="LoginCreateAccount-btn">Crear cuenta</Link>
        </div>
    )
}
