import React from 'react'
import Input from "../Input"
import { Link, navigate } from 'gatsby'

export default function LoginSignIn() {

    return (
        <div className="LoginSignIn">
            <h3 className="LoginSignIn-title">Accede a tu cuenta</h3>
            <form onSubmit={(e) => {e.preventDefault()} }>
                <Input label="E-mail" type="email" />
                <Input label="Contraseña" type="password" />
                <Link className="LoginSignIn-link">¿Has olvidado tu contraseña?</Link>
                <button className="LoginSignIn-btn" onClick={() => {navigate('/privado/pedidos/')}}>Acceder</button>
            </form>
        </div>
    )
}
